module.exports = {
  intro: {
    title1: "Introducing Pay with Points",
    title2: "by AYANA Rewards",
    subtitle:
      "Turn your AYANA Rewards Points into Unforgettable Experiences at AYANA Hotels",
    cta: "CHECK RATES",
  },
  title: "Pay with Points for Exclusive AYANA Getaways",
  pros: {
    first: {
      title: "Experience exclusive payment flexibility",
      description:
        "Pay for your AYANA Hotel stay effortlessly with points. If your points fall short, you have the option to supplement with cash for an enhanced experience.",
    },
    second: {
      title: "Maximize your savings",
      description:
        "Redeem your accumulated points for exclusive member discounts, elevating your experience with unparalleled savings.",
    },
  },
  preferred_dest: {
    title: "Choose Your Preferred AYANA Destination",
    subtitle:
      "AYANA Hotels span across Jakarta, Bali, and Komodo, featuring renowned brands such as AYANA Villas Bali, AYANA Segara Bali, AYANA Resort Bali, RIMBA by AYANA Bali, AYANA Komodo Waecicu Beach, AYANA Lako Di’a, AYANA Midplaza Jakarta, and Delonix Hotel Karawang.",
    bali: "Bali",
    jakarta: "Greater Jakarta",
    komodo: "Komodo",
    RJB: "RIMBA by AYANA Bali",
    ARSB: "AYANA Resort Bali",
    VILLA: "AYANA Villas Bali",
    AKWB: "AYANA Komodo Waecicu Beach",
    LKD: "AYANA Lako Di’a",
    DXKR: "DELONIX Hotel KARAWANG",
    JKTHB: "AYANA Midplaza Jakarta",
    SEGARA: "AYANA Segara Bali",
  },
  how_it_works: {
    title:
      "Your Loyalty Now Unlocks The Power to Pay with Points at All AYANA Hotels",
    subtitle: "Here's how it works:",
    first: "Click the “Find Hotels” Button below",
    second: "Select your preferred dates and location",
    third: "Choose rates labeled “Eligible to use AYANA Rewards Points”",
    fourth: "Log in to your AYANA Rewards account",
    fifth: "Proceed the payment via Pay with Points",
    sixth: "Enjoy your stay!",
  },
  find_hotels: {
    title: "Turn your points into unforgettable AYANA moments now!",
    cta: "FIND HOTELS",
  },
  faq: {
    title: "Frequently Asked Questions",
    first: {
      question: "How does creating booking with AYANA Rewards Points work?",
      answer:
        "When you choose this option, your points will be used to cover the number of points required for your booking. You can decide how many points you want to redeem to pay for the booking and pay the remaining booking amount using the other available payment methods.",
    },
    second: {
      question: "Can I cancel my booking if I paid using points?",
      answer:
        "Your booking’s cancellation policy will follow cancellation policy from the rate you selected. If the selected rate is refundable, amount paid using points will be refunded as points and the portion paid using another payment method will be refunded according to your payment method.",
    },
    third: {
      question:
        "Can I earn points if I paid my bookings with AYANA Rewards points?",
      answer:
        "Yes, you can still earn points on the eligible charges for the bookings created through our website and paid using AYANA Rewards points. Eligible costs vary between hotels. Please refer to our Terms & Conditions for complete information. Please note that AYANA Rewards reserves the right to change the policy in the future without prior notice.",
    },
  },
  tnc: "Terms & Conditions",
};
