import React from "react";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import PayByPoints from "../components/TWComponents/PayByPoints";
import langData from "../data/static-pages/pages/pay-with-points/en";

const PayByPointsPage = (props) => {
  const [openMobileNav, setOpenMobileNav] = React.useState(false);
  const [openWidget, setOpenWidget] = React.useState(false);

  const openWidgetAction = () => {
    setOpenWidget(!openWidget);
  };

  return (
    <Layout
      location={props.location}
      showBookNowButton={false}
      mobileWidgetStatus={openWidget}
      setMobileWidgetStatus={setOpenWidget}>
      <SEO
        title="Pay with Points"
        keyword="Pay with Points"
        decription="Pay with Points"
      />
      <PayByPoints
        openWidgetAction={openWidgetAction}
        langData={langData}
        locale="en"
        tnclink="https://rewards.ayana.com/terms-and-conditions-8"
      />
    </Layout>
  );
};

export default PayByPointsPage;
